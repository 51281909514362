import React from "react";

import {Router} from "@reach/router";

import PrivateRoute from "../components/PrivateRoute";

import Diary from "./app/diary";
import Feedback from "./app/feedback";
import Resources from "./app/resources";
import Guide from "./app/guide";

import {routes} from "../data/routes";

const App = () => (
	<>
		<Router>
			<PrivateRoute path={routes.diary} component={Diary}/>
			<PrivateRoute path={routes.feedback} component={Feedback}/>
			<PrivateRoute path={routes.resources} component={Resources}/>
			<PrivateRoute path={routes.guide} component={Guide}/>
		</Router>
	</>
);

export default App


