import React, {useContext} from "react";

import {navigate} from "gatsby";

import PropTypes from "prop-types";

import {authContext} from "../contexts/authContext";

const PrivateRoute = ({component: Component, location, ...rest}) => {
	const auth = useContext(authContext);

	if (!auth.isLoggedIn && location.pathname !== `/login` && !auth.isLoading) {
		// If we’re not logged in, redirect to the home page.
		navigate(`/login`);
		return null;
	}

	return <Component {...rest} location={location}/>;
};

PrivateRoute.propTypes = {
	component: PropTypes.any.isRequired,
};

export default PrivateRoute;
